.img-fluid {
    display: flex;
    border-radius: 1px;
    border-radius: 20px;
    transition: opacity 1s ease-in-out;
    /* padding: 10px 0px 20px 0px; */
    margin: 10px 0px 20px 0px;
    animation: fadeInComingDown 1s ease-in-out;
}
@keyframes fadeInComingDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .resume-link {
    color: white;
    font-weight: 800;
    font-size: 16px;
    z-index: 100;
    text-decoration: none; /* Remove the default underline */
    transition: text-decoration 0.3s ease; /* Add a transition effect */
  }