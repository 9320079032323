@import url(global.css);

.content {
    background-color: #212121;
    font-family: 'Roboto', sans-serif;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: auto 1fr;
    box-sizing: border-box;
    overflow-x: hidden;
}

@media screen and (max-width: 500px) {
    .content {
        background-color: #212121;
        font-family: 'Roboto', sans-serif;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .sidebar {
        z-index: 2;
        height: 100vh;
        width: 245px;
    }

}

.right-content {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto 1fr;
    /* overflow: hidden; */
}

@media screen and (max-width: 500px) {
    .right-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 100vh;
        z-index: 1;
        position: absolute;
    }
}

/* Header Styling */
.header {
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    border: 1px solid var(--primary-color-black);
    color: white;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0.25em 0.5em 0 0.25em;
}

.header-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;

}

.header-title h2 {
    color: var(--primary-color-white);
}
/* Stick Header */
.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;

}

/* Nav Button Header */
.nav-button {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(0deg,rgba(53,55,64,0),#212121 20.85%);
}


.nav-button:hover {
    cursor: pointer;
}


.sticky h5 {
    flex-grow: 1;
    text-align: center;
    margin: 0;
  }
  
  .sidebar-open-button {
    margin-right: auto;
  }


/* Styling Page Body */

.page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
}

@media screen and (max-width: 500px) {
    .page-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        width: 100%;
        height: calc(100% - 61px);
    }
    
}

.page-body.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
div#anchor {
    z-index: 100;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(180deg,rgba(53,55,64,0),#212121 58.85%);
    position: sticky;
    bottom: 0;
}

.search.container {
    margin: 0 0.5em;
    margin-bottom: 0.5em;
    max-width: 820px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* opacity: 0; */
    /* animation: opacityAnimation 1s ease forwards 5.5s; */
}

  .sidebar-open-button {
    order: -1; /* Move the button to the beginning */
  }

@keyframes opacityAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.search-bar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    background-color: #212121;
    border-radius: 10px;
    border: 1px solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    border: 1px solid rgba(86, 88, 105, 1);
    min-height: 35px;
    padding: 10px 10px 10px 5px;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.search-input {
    background-color: #212121;
    border: none;
    width: 100%;
    caret-color: white;
    color: white;
    font-size: 16px;
    font-weight: 400;
}

.search-input:focus {
    outline: none;
    box-shadow: none;
}

.search-input:hover {
    outline: none;
    border: none;
}

.search-input::placeholder {
    color:white;
}

*:focus {
    outline: none;
}

.search-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    top: 50%;
    left: 10px;
    font-size: 18px;
    color: var(--primary-color-grey);
    transition: color 0.3s ease;
}

.search-icon-button.active {
    color: white;
    transition: color 0.3s ease;
}

.search-button {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    background-color: none;
    transition: background-color 0.3s ease;
    height: 30px;
    padding: 1px;
    width: 30px;
    border-radius: 5px;
    background-color: rgb(76,77,87);
}

.search-button.active {
    color: white;
    background-color: rgb(25, 195, 125);
    transition: background-color 0.3s ease;
    border-radius: 5px;
}


::placeholder {
    color: white;
    opacity: 0.5;
}

::-ms-input-placeholder {
    color: white;
}

@keyframes cardFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}


.card-group {
    margin: 50px 0px;
    padding: 0 1em;
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
}

.query-card {
    background-color: transparent;
    height: 60px;
    width: 100%;
    color: white;
    border: 1px solid rgba(86, 88, 105, 1);
    border-radius: 10px;
    /* margin: 0.5rem; */
    /* opacity: 0; */
    /* transform: scale(0.9); */
    /* animation: cardFadeIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards 7s; */
}

.ant-btn:hover {
    border-color: rgba(86, 88, 105, 1) !important;
}

.query-card:hover {
    background-color: rgb(64, 65, 79);
    transition: background-color 0.3s ease;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.query-card-title {
    font-size: 14px;
    font-weight: 700;
    color: rgba(197, 197, 210, 1);
}

.query-card-description {
    font-size: 13px;
    font-weight: 500;
    color: rgba(172, 172, 190, 0.5);
}


.query-footer {
    font-size: .75rem;
    line-height: 1rem;
    color: rgba(197, 197, 210, 1);
    font-weight: 400;
}