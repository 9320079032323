:root {
    font-family: 'Inter', sans-serif;
  }
  
  @supports (font-variation-settings: normal) {
    :root {
      font-family: 'Inter var', sans-serif;
    }
  }
  
  .introduction-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0px 0px 0px;
    width: 100vw;
    /* height: 100%; */
    z-index: 50;
  }
  .small-intro {
    font-size: 20px;
    color: rgba(197, 197, 210, 1);
  }
  .name-intro {
    font-weight: bolder;
    margin: 0; /* This resets all margins to zero */
    font-size: clamp(40px, 8vw, 100px);
    color: rgba(197, 197, 210, 1);
  }

  .intro-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    animation: exitAnimation 0.5s ease forwards 10.5s;
  }
  
  .onboarding {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 820px;
    margin-top: 20px;
    font-size: clamp(40px, 8vw, 50px);
    color: rgb(142, 142, 160);
    opacity: 0;
    transform: translateY(20px);
    animation: appearAnimation 0.5s ease forwards 1.5s, exitAnimation 0.5s ease forwards 10s;
}

@keyframes appearAnimation {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes exitAnimation {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

