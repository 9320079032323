.response-container {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    align-items: center;
    overflow: auto;
    overflow-x: hidden;
    color: var(--primary-color-white);
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
}

.grow {
    color: transparent;
    /* background-color: rgba(52,53,65,1); */
    width: 100%;
}

.response-content {
    width: 100%;
}

.response-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5em;
    max-width: 820px;
    width: 100%;
}

.response-span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5em;
    width: 100%;
    box-sizing: border-box;
}


.response-span.answer {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(32,33,35,.5);
    /* background-color: rgba(68,70,84, 1); */
}

.response-span {
    display: flex;
    justify-content: center;
}

.response-message {
    display: flex;
    align-items: flex-start;
    /* width: 820px; */
    text-align: left;
    gap: 1.5em;
    width: 100%;
}
@media (min-width: 1024px) {
    .response-title {
        max-width: 38rem;
    }
    .response-span {
        display: flex;
    }
}

.response-body {
    max-width: 38rem;
    display: flex;
    width: 100%;
}


.avatar-container {
    flex-shrink: 0;
    padding-top: 20px;
}

.avatar-container:hover {
    cursor: pointer;
}

.avatar {
    width: 36px;
    height: auto;
    border-radius: 20px;
}

.response-text-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 80px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 2em 0 0;
}

.response-text {
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem;
    color: #ececf1;
}

.response-cursor {
    opacity: 1;
    animation: blink 1s infinite;
    display: inline-block;
    vertical-align: middle;
    width: 0.5em;
    height: 1.07em;
    background-color: #ececf1;
    margin-left: 0.1em;
}

@keyframes blink {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}



