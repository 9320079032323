.sidebar {
    background-color: #000000;
    color: white;
    width: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease, width 0.3s;
}

.sidebar.active {
    padding: 0.5rem;
    width: 245px;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    position: sticky;
    top: 0;
}



.sidebar-open-button {
    margin: 0.5em;
    background: var(--primary-color-black);
    border: none;
    color: var(--primary-color-white);
}

.sidebar-open-button:hover {
    background-color: var(--hover-color-grey);
}

.sidebar-open-icon:hover {
    color: var(--primary-color-white);
}

.sidebar-title {
    display: flex;
    align-items: center;
}

.sidebar-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 44px;
    background-color: transparent;
    border: 1px solid rgba(86, 88, 105, 1);
    cursor: pointer;
    color: white;
}

.sidebar-heading {
    background-color: transparent;
    display: flex;
    border: 1px solid rgba(86, 88, 105, 1);
    line-height: 1.25rem;
    font-size: .875rem;
    min-height: 44px;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-radius: 0.375rem;
    font-weight: 500;
    margin: 0px 8px 0 0;
    color: white;
    flex: 2;
}

.sidebar-heading:hover {
    background-color: rgb(64, 65, 79, 0.5);
    transition: background-color 0.3s ease;
}

.sidebar-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    padding: 0.5rem;
    margin-top: auto;
    justify-content: flex-start;
    margin-top: -15px;
}

.sidebar-item {
    padding: 8px 0;
    width: 100%;
    transition: background-color 0.3s ease;
}

.sidebar-item:hover {
    background-color: rgba(52, 53, 65, 0.5);
    border-radius: 5px;
}

.sidebar-item.active {
    background-color: rgba(52, 53, 65, 1);
    border-radius: 5px;
}

.nav-item {
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    font-size: 16px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 10px;
    color: white;
    text-decoration: none;
    font-family: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
}

.nav-item:hover {
    cursor: pointer;
    /* background-color: rgba(52, 53, 65, 0.5); */
    /* border-radius: 5px; */
}