.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
    height: 1rem;
    width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  /* background-color: rgba(217,217,227,.8); */
  background-color:hsla(0,0%,100%,.5);
  border-color: rgba(255,255,255,var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
}

::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236,236,241,var(--tw-bg-opacity));
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar {
  background-color: rgb(185,185,195) !important; 
  scrollbar-width: thin; 
}


button.ant-modal-close {
  display: none;
}

.popup-modal .ant-modal-content {
  background-color: rgb(25, 26, 27);
  color: white; 
  padding: 20px 25px;
}

.popup-modal-content .modal-heading {
  color: #D9D9DE;
  font-size: 20px;
  font-weight: 600;
}

.popup-modal-content .sub-heading {
  color: #D9D9DE;
  font-size: 14px;
  font-weight: 450;
  margin-top: -15px;
}

.popup-modal-content .divider {
  margin: 20px -20px 15px -20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.146);
}

.feature-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feature {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.feature:last-child {
  margin-bottom: 30px;
}
